var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "b-dropdown",
                {
                  staticClass: "filter-dropdown",
                  attrs: {
                    id: "dropdown-1",
                    size: "sm",
                    text: _vm.filterTextReports,
                    "toggle-class": _vm.filterClassReports,
                  },
                },
                [
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "0",
                          },
                          on: { change: _vm.getItems },
                          model: {
                            value: _vm.filter_reports,
                            callback: function ($$v) {
                              _vm.filter_reports = $$v
                            },
                            expression: "filter_reports",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.BILLING.REPORTS.0")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "1",
                          },
                          on: { change: _vm.getItems },
                          model: {
                            value: _vm.filter_reports,
                            callback: function ($$v) {
                              _vm.filter_reports = $$v
                            },
                            expression: "filter_reports",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.BILLING.REPORTS.1")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "2",
                          },
                          on: { change: _vm.getItems },
                          model: {
                            value: _vm.filter_reports,
                            callback: function ($$v) {
                              _vm.filter_reports = $$v
                            },
                            expression: "filter_reports",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.BILLING.REPORTS.2")))]
                      ),
                    ],
                    1
                  ),
                  _vm.filter_reports.length != 0
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.filter_reports.length != 0
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.clearFilterReports } },
                        [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR_SELECTED")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "mt-2",
            attrs: {
              items: _vm.items,
              fields: _vm.fields,
              responsive: "",
              busy: _vm.loading,
              small: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "head(organisation_id)",
                  fn: function () {
                    return [
                      _vm.items.length != 0
                        ? _c("b-form-checkbox", {
                            on: { change: _vm.addAll },
                            model: {
                              value: _vm.add_all,
                              callback: function ($$v) {
                                _vm.add_all = $$v
                              },
                              expression: "add_all",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(id)",
                  fn: function (data) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-fill",
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getReport(data.item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fal fa-download" })]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(data.item.period) +
                          " - " +
                          _vm._s(data.item.organisation_name) +
                          "\n    "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(name)",
                  fn: function (data) {
                    return [_vm._v(_vm._s(_vm.$t(data.item.name)))]
                  },
                },
                {
                  key: "cell(created_date)",
                  fn: function (data) {
                    return [
                      _c("i", {
                        staticClass: "fal fa-download clickable",
                        attrs: { target: "_blank" },
                        on: {
                          click: function ($event) {
                            return _vm.getReport(data.item)
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(organisation_id)",
                  fn: function (data) {
                    return [
                      _c("b-form-checkbox", {
                        attrs: { name: "check-button", value: data.item.id },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(state)",
                  fn: function (data) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-fill",
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getReport(data.item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fal fa-download" })]
                      ),
                    ]
                  },
                },
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [
                          _c("b-spinner", {
                            staticClass: "align-middle",
                            attrs: { variant: "secondary" },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              860067517
            ),
          }),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                _vm.items.length != 0
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-form",
                          { attrs: { inline: "" } },
                          [
                            _c("b-form-select", {
                              staticClass: "mr-2 medium-select",
                              attrs: {
                                disabled: _vm.selected.length == 0,
                                options: _vm.bulk_options,
                              },
                              model: {
                                value: _vm.bulk_action,
                                callback: function ($$v) {
                                  _vm.bulk_action = $$v
                                },
                                expression: "bulk_action",
                              },
                            }),
                            _vm.bulk_action == "remove"
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      disabled: _vm.selected.length == 0,
                                      variant: "danger",
                                    },
                                    on: { click: _vm.actionReports },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("b-pagination", {
                    staticClass: "mt-2",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.limit,
                      align: "center",
                      pills: "",
                    },
                    on: { input: _vm.getItems },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }