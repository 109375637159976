<template>
  <section v-if="license">

    <div class="mt-2">
      <b-dropdown id="dropdown-1" class="filter-dropdown" size="sm" :text="filterTextReports" :toggle-class="filterClassReports">
        <b-dropdown-form>
          <b-form-checkbox @change="getItems" size="sm" v-model="filter_reports" name="check-button" value="0">{{ $t('FILTER.BILLING.REPORTS.0') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form>
          <b-form-checkbox @change="getItems" size="sm" v-model="filter_reports" name="check-button" value="1">{{ $t('FILTER.BILLING.REPORTS.1') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form>
          <b-form-checkbox @change="getItems" size="sm" v-model="filter_reports" name="check-button" value="2">{{ $t('FILTER.BILLING.REPORTS.2') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-divider v-if="filter_reports.length != 0"></b-dropdown-divider>
        <b-dropdown-item v-if="filter_reports.length != 0" @click="clearFilterReports">{{ $t('FILTER.CLEAR_SELECTED') }}</b-dropdown-item>
      </b-dropdown>
    </div>

    <b-table
      class="mt-2"
      :items="items"
      :fields="fields"
      responsive
      :busy="loading"
      small
    >
      <template #head(organisation_id)>
        <b-form-checkbox
          v-if="items.length != 0"
          v-model="add_all"
          @change="addAll"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(id)="data">
        <b-button
          size="sm"
          variant="primary"
          class="btn-fill"
          @click="getReport(data.item)"
          ><i class="fal fa-download"></i
        ></b-button> {{ data.item.period }} - {{ data.item.organisation_name }}
      </template>
      <template v-slot:cell(name)="data">{{ $t(data.item.name) }}</template>

      <template v-slot:cell(created_date)="data">
        <i
          target="_blank"
          class="fal fa-download clickable"
          @click="getReport(data.item)"
        ></i>
      </template>

      <template v-slot:cell(organisation_id)="data">
        <b-form-checkbox
          v-model="selected"
          name="check-button"
          :value="data.item.id"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(state)="data">
        <b-button
          size="sm"
          variant="primary"
          class="btn-fill"
          @click="getReport(data.item)"
          ><i class="fal fa-download"></i
        ></b-button>
      </template>

      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner variant="secondary" class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col cols="12" md="6">
        <div class="mt-2" v-if="items.length != 0">
          <b-form inline>
            <b-form-select
              :disabled="selected.length == 0"
              class="mr-2 medium-select"
              v-model="bulk_action"
              :options="bulk_options"
            ></b-form-select>
            <b-button
              :disabled="selected.length == 0"
              variant="danger"
              v-if="bulk_action == 'remove'"
              @click="actionReports"
              >{{ $t("REMOVE") }}</b-button
            ></b-form
          >
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <b-pagination
          class="mt-2"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="limit"
          align="center"
          @input="getItems"
          pills
        >
        </b-pagination>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import * as blobUtil from "blob-util";
export default {
  props: ["license", "from_date","limit","searchText"],
  components: {
  },
  data() {
    return {
      filter_reports: [],
      clearOption: false,
      add_all: false,
      selected: [],
      bulk_action: "remove",
      bulk_options: [{ value: "remove", text: this.$t("REMOVE") }],
      items: [],
      createdReportIndex: 0,
      totalItems: 0,
      currentPage: 1,
      report_type: "",
      loading: false,
      report_types: [],
      fields: [
        {
          key: "organisation_id",
          class: "text-break w-icon",
          label: "",
        },
        {
          key: "state",
          class: "text-break hidden-mobile w-icon",
          label: "",
        },
        {
          key: "id",
          label: "",
          class: "hidden-not-mobile",
          thClass: "",
        },
        {
          key: "period",
          class: "text-break hidden-mobile",
          label: this.$t("PERIOD"),
        },
        {
          key: "organisation_name",
          class: "text-break hidden-mobile",
          label: this.$t("ORGANISATION.TITLE"),
        },
        {
          key: "name",
          class: "text-break hidden-mobile",
          label: this.$t("BILLING.REPORT"),
        },
        {
          key: "node_description",
          class: "text-break hidden-mobile",
          label: this.$t("DESCRIPTION"),
        },
      ],
    };
  },
  methods: {
    clearFilterReports()
    {
      this.filter_reports = [];
      this.getItems();
    },
    clearFilterAll(){
      this.getItems = [];
      this.getInbox();
    },
    changedMonth(){
      this.getItems();
    },
    addAll: function() {
      if (this.add_all) {
        this.selected = this.items.map((a) => a.id);
      } else {
        this.selected = [];
      }
    },
    iterateReportTypes() {
      if (this.license.type == "ML") {
        this.report_types.push({ text: this.$t("ALL"), value: "" });
        this.report_types.push({
          text: this.$t("BILLING.RESELLER_REPORT"),
          value: "partner",
        });
        this.report_types.push({
          text: this.$t("BILLING.NODE_REPORT"),
          value: "node",
        });
        this.report_types.push({
          text: this.$t("BILLING.ORGANISATION_REPORT"),
          value: "organisation",
        });
      }
      if (this.license.type == "PA") {
        this.report_types.push({ text: this.$t("ALL"), value: "" });
        this.report_types.push({
          text: this.$t("BILLING.RESELLER_REPORT"),
          value: "partner",
        });
        this.report_types.push({
          text: this.$t("BILLING.NODE_REPORT"),
          value: "node",
        });
        this.report_types.push({
          text: this.$t("BILLING.ORGANISATION_REPORT"),
          value: "organisation",
        });
      }
      if (this.license.type == "RS") {
        this.report_types.push({ text: this.$t("ALL"), value: "" });
        this.report_types.push({
          text: this.$t("BILLING.NODE_REPORT"),
          value: "node",
        });
        this.report_types.push({
          text: this.$t("BILLING.ORGANISATION_REPORT"),
          value: "organisation",
        });
      }
    },
    getItems: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/billing/report/list",
          {
            page: this.currentPage,
            limit: parseInt(this.limit),
            search_text: this.searchText,
            report_type: this.report_type,
            period: this.from_date,
            filter_reports: this.filter_reports
          }
        )
        .then((response) => {
          self.items = response.data.items;
          self.totalItems = response.data.count;
          if(self.totalItems == 0)
          {
            self.add_all = false;
          }
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    actionReports: function() {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function(value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/billing/reports/action", {
                reports: self.selected,
                action: self.bulk_action
              })
              .then(() => {
                self.getItems();
              })
              .catch(function() {
              });
          }
        })
        .catch(function() {});
    },
    getReport: function(item) {
      let id = item.id;
      let name = item.organisation_name + "_" + item.period + ".pdf";
      this.$http
        .get(this.user.hostname + "/billing/report/" + id)
        .then((response) => {
          let blob = blobUtil.base64StringToBlob(
            response.data,
            "application/pdf"
          );
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, name);
          } else {
            let $link = document.createElement("a");
            let url = URL.createObjectURL(blob);
            $link.setAttribute("target", "_blank");
            $link.setAttribute("href", url);
            $link.setAttribute("download", name);
            $link.style.visibility = "hidden";
            document.body.appendChild($link);
            $link.click();
            document.body.removeChild($link);
          }
        })
        .catch(function() {
          //console.log(error);
        });
    },
    showLicense: function(license) {
      return this.$t("LICENSE.LEVELS." + license);
    },
    refreshList() {
      this.getItems();
      this.createdReportIndex++;
    },
    redrawTable() {
      this.getLicense();
    }
  },
  computed: {
    showFilterClearAll(){
      if(this.filter_reports.length != 0)
      {
        return true;
      }
      return false;
    },
    filterClassReports() {
      if(this.filter_reports.length != 0)
      {
        return  ['filter-selected','no-border'];
      }else{
        return ['no-border'];
      }
    },
    filterTextReports() {
      if(this.filter_reports.length != 0)
      {
        if(this.filter_reports.length > 1)
        {
          return this.$t('FILTER.BILLING.REPORTS.TITLE') + ": " + this.$t('FILTER.BILLING.REPORTS.' + this.filter_reports.slice().sort()[0]) + " +(" + (this.filter_reports.length-1) + ")";
        }else{
          return this.$t('FILTER.BILLING.REPORTS.TITLE') + ": " + this.$t('FILTER.BILLING.REPORTS.' + this.filter_reports.slice().sort()[0]);
        }
      }else{
         return this.$t('FILTER.BILLING.REPORTS.TITLE');
      }
    },
    createdReportKey() {
      return "createdReportKey_" + this.createdReportIndex;
    }
  },
  watch: {
    filter_reports: function(new_array)
    {
        localStorage.setItem("billing_report_type", JSON.stringify(new_array));
    },
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getItems();
      }
      if (this.searchText.length >= 2) {
        this.getItems();
      }
    },
  },
  mounted: function() {
    if (localStorage.getItem("billing_report_type") !== null) {
      this.filter_reports = JSON.parse(localStorage.getItem("billing_report_type"));
    }
    this.getItems();
  },
};
</script>
<style></style>
